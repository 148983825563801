import React from "react"
import type { PageProps } from "gatsby"
import NewsNestedPostPage from "../../sections/OldDesign/news/Nested/NewsNestedPostPage"
import { WPConstants } from "../../sections/OldDesign/news/Constants"

const ProductReleasesUpgrades: React.FC<PageProps> = ({ location }) => {
    return (
        <NewsNestedPostPage
            pathname={location.pathname}
            title="Product Releases & Upgrades"
            categoryIds={[WPConstants.CATEGORIES.SOFTWARE_UPDATE]}
        />
    )
}

export default ProductReleasesUpgrades
